import { ModalState } from "@/lib/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ModalState = {
  title: "",
  body: "",
  cancelBtn: "",
  open: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setModalData: (
      state,
      action: PayloadAction<{ [K in keyof ModalState]: ModalState[K] }>
    ) => {
      return { ...state, ...action.payload };
    },
    resetModal: () => initialState,
  },
});

export const { showModal, resetModal, setModalData} = modalSlice.actions;
export default modalSlice.reducer;
