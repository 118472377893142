import type { Metadata, Viewport } from "next";
import { Inter } from "next/font/google";
import "../globals.css";
import StoreProvider from "../StoreProvider";
import { Toaster } from "react-hot-toast";

const inter = Inter({ subsets: ["latin"] });

// export const metadata: Metadata = {
//     title: "Micro Mojo",
//     description:
//         "Micro Mojo is a mobile game that helps you improve your relationship with your partner.",
// };

// best viewed on mobile devices
export const viewport: Viewport = {
  themeColor: "#fffafa",
  initialScale: 1,
  width: "device-width",
  height: "device-height",
  minimumScale: 1,
  maximumScale: 1,
  userScalable: false,
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StoreProvider screenId={0}>
      {" "}
      <Toaster />
      {children}
    </StoreProvider>
  );
}
