"use client";
import { useRef } from "react";
import { Provider } from "react-redux";
import { makeStore, AppStore } from "../lib/store";
import { initializeScreen } from "@/lib/features/slices/screensSlice";

export default function StoreProvider({
    screenId,
    children,
}: {
    screenId: number;
    children: React.ReactNode;
}) {
    const storeRef = useRef<AppStore | null>(null);
    if (!storeRef.current) {
        storeRef.current = makeStore();
        storeRef.current.dispatch(initializeScreen(screenId));
    }

    return <Provider store={storeRef.current}>{children}</Provider>;
}
