import { login } from "@/app/api/endpoints/endpoints";
import { useAppDispatch } from "@/lib/hooks";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initializeScreen } from "./screensSlice";
import { PhoneValues } from "@/lib/types/types";
import parsePhoneNumberFromString, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";
import { setModalData } from "./modalSlice";
import toast from "react-hot-toast";

interface FormState {
  isDisabled: boolean;
  code: string;
  codeError: boolean;
  userPhase: string;
  values: PhoneValues;
  errors: PhoneValues;
}

const initialState: FormState = {
  isDisabled: false,
  code: "",
  codeError: false,
  userPhase: "",
  values: {
    phone: "",
    country: "EG",
  },
  errors: {
    phone: "",
    country: "EG",
  },
};

const phoneSlice = createSlice({
  name: "phoneScreen",
  initialState,
  reducers: {
    setFormValue: (
      state,
      action: PayloadAction<{
        name: keyof PhoneValues;
        value: any;
      }>
    ) => {
      const { name, value } = action.payload;
      state.values[name] = value;
      state.errors[name] = "";
      if (name === "phone") {
        state.values[name] = value.replace(/\D/g, "");
      } else {
        state.values[name] = value;
      }
    },
    setFormError: (
      state,
      action: PayloadAction<{ name: keyof PhoneValues; error: string }>
    ) => {
      const { name, error } = action.payload;
      state.errors[name] = error;
    },
    setOTPValue: (
      state,
      action: PayloadAction<{ name: "code"; value: string }>
    ) => {
      const { name, value } = action.payload;
      state["code"] = value;
      state["codeError"] = false;
    },
    setOTPError: (
      state,
      action: PayloadAction<{ name: "codeError"; error: boolean }>
    ) => {
      const { name, error } = action.payload;
      state["codeError"] = error;
    },
    resetForm: () => initialState,
    handleResendCode: (state) => {
      const phoneNumber = parsePhoneNumberFromString(
        state.values.phone,
        state.values.country
      )?.number;
      const queryData: { phone: any } = {
        phone: phoneNumber,
      };
      login(queryData).then();
      state.isDisabled = true; // Set isDisabled to true
    },
    setIsDisabled: (state) => {
      state.isDisabled = false;
    },
    setUserPhase: (state, action: PayloadAction<string>) => {
      state.userPhase = action.payload;
    },
  },
});

export const {
  setFormValue,
  setFormError,
  resetForm,
  setOTPValue,
  setOTPError,
  handleResendCode,
  setIsDisabled,
  setUserPhase,
} = phoneSlice.actions;
export default phoneSlice.reducer;

export const handleFormValidation = () => (dispatch: any, getState: any) => {
  const values = getState().phoneFormScreen.values;
  const errors = getState().phoneFormScreen.errors;

  let hasErrors = false;
  const newErrors: PhoneValues = {
    phone: "",
    country: "EG",
  };
  const testPhone = isPossiblePhoneNumber(values.phone, values.country);
  const validPhone = isValidPhoneNumber(values.phone, values.country);
  const validLength = validatePhoneNumberLength(values.phone, values.country);

  if (!testPhone || !validPhone || validLength !== undefined) {
    newErrors.phone = "Invalid mobile number";
    hasErrors = true;
  }
  if (hasErrors) {
    const updatedErrors: PhoneValues = {
      ...errors,
      ...newErrors,
    };

    dispatch(setFormError({ name: "phone", error: updatedErrors.phone }));
    dispatch(setFormError({ name: "country", error: updatedErrors.country }));

    return;
  } else {
    const phoneNumber = parsePhoneNumberFromString(
      getState().phoneFormScreen.values.phone,
      getState().phoneFormScreen.values.country
    )?.number;
    const queryData: { phone: any } = {
      phone: phoneNumber,
    };
    login(queryData)
      .then((data) => {
        if (data?.data?.status === "pending") {
          dispatch(setUserPhase("loginPhase"));
          dispatch(initializeScreen(2));
        } else {
          throw new Error(data?.data?.message || "Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again!");
      });
  }
};

export const useHandlePhoneForm = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(handleFormValidation());
  };
};
