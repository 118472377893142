import { loginVerify, register } from "@/app/api/endpoints/endpoints";
import { useAppDispatch } from "@/lib/hooks";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { incremented, initializeScreen } from "./screensSlice";
import { FormValues } from "@/lib/types/types";
import parsePhoneNumberFromString from "libphonenumber-js";
import { setOTPError } from "./phoneSlice";
import validator from "validator";
import { resetModal, setModalData } from "./modalSlice";
import toast from "react-hot-toast";

enum UserStatus {
  NotRegistered = "NotRegistered",
  Registered = "Registered",
  Paid = "Paid",
}
enum AppCode {
  INSUFFICIENT_PERMISSIONS = 403,
  VALIDATION_ERROR = 422,
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
}
interface FormState {
  accessToken: string;
  values: FormValues;
  errors: FormValues;
}

const initialState: FormState = {
  accessToken: "",
  values: {
    firstName: "",
    surname: "",
    email: "",
    confirmEmail: "",
    acceptTermsCond: "",
  },
  errors: {
    firstName: "",
    surname: "",
    email: "",
    confirmEmail: "",
    acceptTermsCond: "",
  },
};

const registrationFormSlice = createSlice({
  name: "registrationForm",
  initialState,
  reducers: {
    setFormValue: (
      state,
      action: PayloadAction<{
        name: keyof FormValues;
        value: any;
      }>
    ) => {
      const { name, value } = action.payload;
      state.values[name] = value;
      state.errors[name] = "";
      state.values[name] = value;
    },
    setFormError: (
      state,
      action: PayloadAction<{ name: keyof FormValues; error: string }>
    ) => {
      const { name, error } = action.payload;
      state.errors[name] = error;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    resetForm: () => initialState,
  },
});

export const { setFormValue, setFormError, resetForm, setAccessToken } =
  registrationFormSlice.actions;
export default registrationFormSlice.reducer;

export const handleFormValidation = () => (dispatch: any, getState: any) => {
  const values = getState().registrationForm.values;
  const errors = getState().registrationForm.errors;
  const acceptTermsCond = getState().termsModal;
  let hasErrors = false;

  const newErrors: FormValues = {
    firstName: "",
    surname: "",
    email: "",
    confirmEmail: "",
    acceptTermsCond: "",
  };

  if (values.firstName.trim() === "") {
    newErrors.firstName = "First Name is required";
    hasErrors = true;
  }
  if (values.surname.trim() === "") {
    newErrors.surname = "Surname is required";
    hasErrors = true;
  }

  if (!validator.isEmail(values.email)) {
    newErrors.email = "Invalid email";
    hasErrors = true;
  }

  if (
    !validator.isEmail(values.confirmEmail) ||
    values.email !== values.confirmEmail
  ) {
    newErrors.confirmEmail = "Invalid email";
    hasErrors = true;
  }
  if (acceptTermsCond?.acceptTermsCond !== true) {
    newErrors.acceptTermsCond = "Please Accept Terms & Conditions";
    hasErrors = true;
  }
  if (hasErrors) {
    const updatedErrors: FormValues = {
      ...errors,
      ...newErrors,
    };
    dispatch(
      setFormError({ name: "firstName", error: updatedErrors.firstName })
    );
    dispatch(setFormError({ name: "surname", error: updatedErrors.surname }));
    dispatch(setFormError({ name: "email", error: updatedErrors.email }));
    dispatch(
      setFormError({ name: "confirmEmail", error: updatedErrors.confirmEmail })
    );
    dispatch(
      setFormError({
        name: "acceptTermsCond",
        error: updatedErrors.acceptTermsCond,
      })
    );

    return;
  } else {
    // const { phone, country } = getState().phoneFormScreen.values;
    // const queryData: { phone: any } = {
    //   phone: parsePhoneNumberFromString(phone, country)?.number,
    // };
    // requestOTP(queryData).then(data => {
    //     data?.data?.status == 'pending' &&
    //         dispatch(incremented());
    // })
    dispatch(handleFormRegAPI());
  }
};

export const handleFormRegAPI = () => async (dispatch: any, getState: any) => {
  const state = getState().registrationForm.values;
  const { values } = getState().phoneFormScreen;
  try {
    const queryData: {
      firstName: any;
      surName: any;
      phone: (string & { __tag?: "E164Number" }) | undefined;
      email: any;
    } = {
      firstName: state.firstName,
      surName: state.surname,
      email: state.email,
      phone: parsePhoneNumberFromString(values.phone, values.country)?.number,
    };
    const data = await register(queryData);
    if (data?.response?.status !== 201) {
      toast.error("Something went wrong, Please try again!");
    }

    if (data?.response?.status === 201) {
      dispatch(incremented());
      dispatch(setAccessToken(data?.data?.backendTokens?.accessToken));
    }
  } catch (error) {
    toast.error("Something went wrong, Please try again!");
  }
};

export const useHandleFormRegAPI = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(handleFormRegAPI());
  };
};
export const handleLoginVerify = () => (dispatch: any, getState: any) => {
  const { values, code } = getState().phoneFormScreen;
  const queryData: any = {
    phone: parsePhoneNumberFromString(values.phone, values.country)?.number,
    code: code,
  };

  if (code?.length !== 4) {
    dispatch(setOTPError({ name: "codeError", error: true }));
  } else {
    loginVerify(queryData)
      .then((data) => {
        const appCode: AppCode = data?.data?.appCode
          ? data?.data?.statusCode
          : data?.data?.statusCode;
        if (
          appCode === AppCode.INSUFFICIENT_PERMISSIONS ||
          appCode === AppCode.UNAUTHORIZED ||
          appCode === AppCode.VALIDATION_ERROR ||
          appCode === AppCode.BAD_REQUEST
        ) {
          dispatch(setOTPError({ name: "codeError", error: true }));
        }
        if (
          appCode === AppCode.INSUFFICIENT_PERMISSIONS &&
          data?.data?.message == "Partners are not allowed to register"
        ) {
          dispatch(initializeScreen(8));
        }
        const userStatus: UserStatus = data?.data?.user
          ? data?.data?.user?.userStatus
          : data?.data?.userStatus;
        if (userStatus === UserStatus.NotRegistered) {
          dispatch(initializeScreen(3));
        } else if (userStatus === UserStatus.Registered) {
          dispatch(setAccessToken(data?.data?.backendTokens?.accessToken));
          dispatch(initializeScreen(4));
        } else if (userStatus === UserStatus.Paid) {
          dispatch(initializeScreen(7));
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again!");

        setTimeout(() => {
          dispatch(resetModal());
        }, 3000);
      });
  }
};
export const useHandleLoginVerify = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(handleLoginVerify());
  };
};

export const useHandleRegisterForm = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(handleFormValidation());
  };
};
