import { configureStore } from "@reduxjs/toolkit";
import screensReducer from "./features/slices/screensSlice";
import registrationFormReducer from "./features/slices/registrationFormSlice";
 import modalReducer from "./features/slices/modalSlice";
import termsModalReducer from "./features/slices/TermsModalSlice";
import paymentFormReducer from "./features/slices/paymentSlice";
import paymentMethodReducer from "./features/slices/paymentMethodSlice";
import phoneReducer from "./features/slices/phoneSlice";
import invitationReducer from "./features/slices/invitationSlice";

export const makeStore = () => {
  return configureStore({
    reducer: {
      screenDetails: screensReducer,
      registrationForm: registrationFormReducer,
      modal: modalReducer,
      termsModal: termsModalReducer,
      paymentForm: paymentFormReducer,
      paymentMethod: paymentMethodReducer,
      phoneFormScreen: phoneReducer,
      invitationScreen: invitationReducer,
    },
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
