import { acceptInvitation, getInvitation } from "@/app/api/endpoints/endpoints";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useRouter } from "next/router";
import { setModalData } from "./modalSlice";
import toast from "react-hot-toast";

interface InvitationState {
  videoId: string;
  videoUrl: string;
  errorMsg: string;
}

const initialState: InvitationState = {
  videoId: "",
  videoUrl: "",
  errorMsg: "",
};

const invitationSlice = createSlice({
  name: "invitation",
  initialState,
  reducers: {
    setVideoId: (state, action: PayloadAction<string>) => {
      state.videoId = action.payload;
    },
    setVideoUrl: (state, action: PayloadAction<string>) => {
      state.videoUrl = action.payload;
    },
    setErrorMsg: (state, action: PayloadAction<string>) => {
      state.errorMsg = action.payload;
    },
  },
});

export const { setVideoId, setVideoUrl, setErrorMsg } = invitationSlice.actions;
export default invitationSlice.reducer;

export const getInvitationData =
  (id: string | string[] | undefined) => async (dispatch: any) => {
    try {
      // Call invitation API to get the video ID
      await getInvitation(id).then((data) => {
        if (data.response.status !== 200) {
          toast.error("Error while getting the record, invalid invitation");
        } else {
          const videoUrl = data?.data.signedUrl;
          dispatch(setVideoUrl(videoUrl));
        }
      });
    } catch (error) {
      console.error("Error retrieving invitation data:", error);
    }
  };
// export const handleAcceptInvitation = (id: string | string[] | undefined) => async (dispatch: any) => {
//     acceptInvitation(id).then((data) => {
//         if (data?.response?.status == 201) {
//         //    const router = useRouter();
//         //    router.push("./invitationAccepted");
//         }
//     });
// };
