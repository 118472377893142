import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentFormState } from "@/lib/types/types";

const paymentForm: paymentFormState = {
  cardHolderName: "",
  cardNumber: "",
  cvv: "",
  expiryDate: "",
};

const initialState = {
  paymentForm,
  open: false
};

const paymentFormSlice = createSlice({
  name: "paymentForm",
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setFormValue: (
      state,
      action: PayloadAction<{ name: keyof paymentFormState; value: string }>
    ) => {
      const { name, value } = action.payload;

      if (name === "cardHolderName") {
        state.paymentForm[name] = value;
      }
      if (name === "cardNumber") {
        const formattedCardNumber = value.replace(/\s/g, ""); // Remove whitespace characters
        const numericCardNumber = formattedCardNumber.replace(/\D/g, ""); // Remove non-digit characters
        const spacedCardNumber = numericCardNumber.replace(/(.{4})/g, "$1 "); // Add space after every 4 characters
        state.paymentForm[name] = spacedCardNumber;
      }
      if (name === "cvv") {
        const formattedCVV = value.replace(/\D/g, ""); // Remove non-digit characters
        state.paymentForm[name] = formattedCVV;
      }
      if (name === "expiryDate") {
        const formattedExpiryDate = value.replace(/\D/g, ""); // Remove non-digit characters
        const month = formattedExpiryDate.slice(0, 2);
        const year = formattedExpiryDate.slice(2, 4);
        state.paymentForm[name] = `${month}/${year}`;
      }
    },

    resetForm: () => initialState,
  },
});

export const { showModal,setFormValue, resetForm } = paymentFormSlice.actions;
export default paymentFormSlice.reducer;
