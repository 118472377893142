import { TermsModalState } from "@/lib/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: TermsModalState = {
  open: false,
  acceptTermsCond: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setTermsCheckBoxValue: (
      state,
      action: PayloadAction<{ name: "acceptTermsCond"; value: boolean }>
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    showModal: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setModalData: (
      state,
      action: PayloadAction<{
        [K in keyof TermsModalState]: TermsModalState[K];
      }>
    ) => {
      return { ...state, ...action.payload };
    },
    resetModal: () => initialState,
  },
});

export const { showModal, resetModal, setModalData, setTermsCheckBoxValue } =
  modalSlice.actions;
export default modalSlice.reducer;
