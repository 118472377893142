import { screensState } from "@/lib/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: screensState = {
  screenId: 0,
  title: "",
  description: "",
  showDots: false,
  image: "",
  showLogo: true,
  showHeader: true,
};

const screensSlice = createSlice({
  name: "screenId",
  initialState,
  reducers: {
    incremented: (state) => {
      state.screenId += 1;
    },
    decremented: (state) => {
      state.screenId -= 1;
    },
    initializeScreen: (state, action: PayloadAction<number>) => {
      state.screenId = action.payload;
    },

    headerData: (state, action) => {
      state.screenId = action.payload;
      switch (action.payload) {
        case 1:
          state.title = "Welcome";
          state.description = `To get started, please enter the mobile number you used to purchase the game. You will then receive a verification code. `;
          state.showDots = true;

          break;
        case 2:
          state.title = "Verification code";
          state.description = `We've sent a verification code to your mobile phone. Please enter it below to confirm the provided mobile number.`;
          state.showDots = true;

          break;
        case 3:
          state.title = "Buying a Lovegame";
          state.description = `To get you started, we just need a few details. Please complete the information below, it'll only take a moment.`;
          state.showDots = true;

          break;
        case 5:
          state.title = "Payment Methods";
          state.description = `Please select your preferred payment method from the options below.`;
          state.showDots = true;
          state.showLogo = true;
          state.showHeader = true;

          break;
        case 6:
          state.title = "Proceed with another payment method";
          state.description = "Please provide your credit card details.";
          state.showDots = false;
          state.showLogo = false;
          state.showHeader = true;
          break;
        case 7:
          state.title = "Congratulations";
          state.description = `Congratulations on taking the first step towards enhancing your life.\n\nWe invite you to experience our Lovegame universe, crafted to offer you a unique journey.\n\nKindly follow the link to the App Store to download our exclusive app. Wishing you an enriching experience.\n\nPlease click below to be directed to the App Store.`;
          state.showDots = false;
          state.showLogo = true;
          state.showHeader = false;

          break;
        default:
          state.title = "";
          state.description = "";
          state.showDots = false;
          state.image = "";
          state.showLogo = true;
          state.showHeader = true;
          break;
      }
    },
  },
});

export const { incremented, decremented, initializeScreen, headerData } =
  screensSlice.actions;
export default screensSlice.reducer;
